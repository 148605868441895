<template>
  <!-- <div class="main-container">
    <p class="rem40 fNormal success">恭喜您成功提款</p>
    <p class="money fNormal success mt8 "><span class="rem40">¥</span>{{ loanData.applyAmt }}</p>
    <div class="text rem28 flex mt24">
      <van-icon name="play" />
      <div>
        <p>恭喜您成功提款{{ loanData.applyAmt }}元</p>
        <p class="mt6">所提款项将放款至商户的{{ loanData.maskCardNo }}卡中</p>
        <p class="mt6">
          以实际放款为准,放款后会<span class="c-4D7BFE">短信通知</span>您,请注意查收。
        </p>
      </div>
    </div>
    <div class="mt40">
      <van-button round block type="info" @click="next">返回首页</van-button>
    </div>
  </div>  -->
  <div
    class="result-container"
    :style="{
      backgroundImage: 'url(' + img1 + ')',
    }"
  >
    <div class="result-main">
      <div class="p-t44 rem46 lh31">
        <span>审核中</span>
      </div>
      <div class="rem30 lh31 c-8E929B">
        <span>请等待，您的提款申请正在审核中</span>
      </div>
      <div class="result-body">
        <div class="rem30 lh21 mb7 f500">温馨提示</div>
        <div class="rem26 lh28 c-303133">
          <div>
            <div class="flex">
              <span class="icon-qq"></span>审核通过后，所提款项将放款至商户的{{
                loanData.maskCardNo
              }}卡中
            </div>
            <div class="flex">
              <span class="icon-qq"></span>放款成功后，将会有短信通知到您，请注意查收
            </div>
          </div>
        </div>
      </div>
      <div class="mt40">
        <van-button round block type="info" @click="next">返回首页</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Icon, Button } from 'vant'

Vue.use(Icon)
Vue.use(Button)
export default {
  data() {
    return {
      loanData: {},
      img1: require('@/assets/images/payment/result1.png'),
    }
  },
  created() {
    if (
      JSON.stringify(this.$route.query) != '{}' &&
      JSON.stringify(this.$route.query.loan) != '{}'
    ) {
      this.loanData = JSON.parse(this.$route.query.loan)
    }
  },
  computed: {},
  methods: {
    next() {
      this.$router.push({
        path: this.$store.state.common.defaultIndex,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.result-container {
  padding: 0 0.42rem /* 21/50 */;
  background-size: 100% 8.4rem /* 420/50 */;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .result-body {
    background-color: #fff;
    margin-top: 0.72rem /* 36/50 */;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    padding: 0.38rem /* 19/50 */ 0.3rem /* 15/50 */;
    border-radius: 4px;
    .icon-qq {
      margin-top: 0.18rem /* 9/50 */;
      display: inline-block;
      width: 0.2rem /* 10/50 */;
      height: 0.2rem /* 10/50 */;
      border-radius: 50%;
      border: 0.04rem /* 2/50 */ solid #4d7bfe;
      box-sizing: border-box;
      margin-right: 0.1rem /* 5/50 */;
    }
  }

  .result-btn {
    margin-top: 0.82rem /* 41/50 */;
  }
  .btn {
    margin-top: 0.48rem /* 24/50 */;
  }
}
</style>
